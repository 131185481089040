<template>
    <div class="info-block">
        <ul class="info" :class="target">
            <li class="info-list" @click="toggleMap" v-if="address !== '' && street !== null && city !== null">
                <div class="info-list__icon">
                    <span class="material-icons-outlined" aria-hidden="true">place</span>
                </div>
                <div class="info-list__content address-block do-not-translate">
                    <div v-if="street !== null && city !== null">
                        <h6 class="text-black">
                            <button class="btn btn-link" aria-hidden="true">{{ street }} {{ city }}</button>
                        </h6>
                    </div>
                    <div class="info-list__content--dropdown" v-if="showMap">
                        <div class="map">
                            <detail-map class="map-container" :content="content" :addressObj="address"
                                style="height:auto!important;"></detail-map>
                        </div>
                    </div>
                </div>
                <div class="info-list__more"><span class="material-icons-outlined" aria-hidden="true">{{ showMap ?
                    'expand_less' : 'expand_more' }}</span></div>
            </li>

            <appointments :appointments="infos.appointments" :appointment-type="infos.appointmentType" />

            <li class="info-list" v-if="costs !== null" :class="Array.isArray(costs) ? '' : 'info-list--center'"
                @click="togglePrices">
                <div class="info-list__icon">
                    <span class="material-icons-outlined" aria-hidden="true">euro</span>
                </div>
                <div class="info-list__content">
                    <h6 class="text-black" v-if="Array.isArray(costs)">{{ costs[0] }}</h6>
                    <h6 class="text-black" v-else>{{ costs }}</h6>
                    <button class="btn btn-link" v-if="Array.isArray(costs)">Zeige weitere Preise oder
                        Ermäßigungen</button>
                    <div v-if="Array.isArray(costs) && showPrices" class="info-list__content--dropdown">
                        <ul class="list">
                            <li v-for="(item, index) in costs" v-if="index > 0">{{ item }}</li>
                        </ul>
                    </div>
                </div>
                <div class="info-list__more" v-if="Array.isArray(costs)"><span class="material-icons-outlined"
                        aria-hidden="true">{{ showPrices ? 'expand_less' : 'expand_more' }}</span></div>
            </li>


            <li class="info-list" @click="toggleSocialMediaLinks" v-if="anyLinks"
                :class="hasOnlyUrl ? 'info-list--center' : ''">
                <div class="info-list__icon">
                    <span class="material-icons-outlined" aria-hidden="true">language</span>
                </div>
                <div class="info-list__content">
                    <h6 class="text-black">
                        <a :href="infos.linkTo.url" target="_blank" alt="url-link">{{ shortenUrl(infos.linkTo.url)
                            }}</a>
                    </h6>
                    <button class="btn btn-link" v-if="!hasOnlyUrl">Zeige soziale Medien</button>
                    <div class="info-list__content--dropdown" v-if="showSocialMediaLinks && !hasOnlyUrl">
                        <ul class="social">
                            <li class="social-item" v-if="infos.linkTo.instagram != null">
                                <span class="icon">
                                    <img :src="getSocialMediaIcons('instagram')"></span>
                                </span>
                                <span class="text">
                                    <a :href="infos.linkTo.instagram" target="_blank" alt="instagram-link">{{
                                        shortenUrl(infos.linkTo.instagram) }}</a>
                                </span>
                            </li>
                            <li class="social-item" v-if="infos.linkTo.facebook != null">
                                <span class="icon">
                                    <img :src="getSocialMediaIcons('facebook')"></span>
                                <span class="text">
                                    <a :href="infos.linkTo.facebook" target="_blank" alt="facebook-link">{{
                                        shortenUrl(infos.linkTo.facebook) }}</a>
                                </span>
                            </li>
                            <li class="social-item" v-if="infos.linkTo.twitter != null">
                                <span class="icon">
                                    <img :src="getSocialMediaIcons('twitter')"></span>
                                </span>
                                <span class="text">
                                    <a :href="infos.linkTo.twitter" target="_blank" alt="twitter-link">{{
                                        shortenUrl(infos.linkTo.twitter) }}</a>
                                </span>
                            </li>
                            <li class="social-item" v-if="infos.linkTo.youtube != null">
                                <span class="icon">
                                    <img :src="getSocialMediaIcons('youtube')"></span>
                                </span>
                                <span class="text">
                                    <a :href="infos.linkTo.youtube" target="_blank" alt="youtube-link">{{
                                        shortenUrl(infos.linkTo.youtube) }}</a>
                                </span>
                            </li>
                        </ul>
                    </div>
                    </Transition>
                </div>
                <div class="info-list__more" v-if="!hasOnlyUrl"><span class="material-icons-outlined"
                        aria-hidden="true">{{ showSocialMediaLinks ? 'expand_less' : 'expand_more' }}</span></div>
            </li>

        </ul>
    </div>
</template>

<script>
import { shortenUrl } from '@/utils/helpers';

export default {
    name: 'InfoBlock',
    components: {
        DetailMap: () => import('@/components/map/single.vue'),
        Appointments: () => import('@/components/appointments/appointments.vue'),
    },
    props: {
        infos: {
            type: Object,
            required: true
        },
        content: {
            type: Object,
            required: false,
            default: {},
        },
        target: {
            type: String,
            default: 'desktop'
        },
    },
    data() {
        return {
            showMap: false,
            showPrices: false,
            showSocialMediaLinks: false,
        }
    },
    computed: {
        address() {
            return this.infos.address;
        },
        street() {
            if (this.address != null && this.address.street !== null) {
                return this.address.street;
            }
            return '';
        },
        city() {
            if (this.address != null && this.address.zipcode !== null && this.address.city !== null) {
                return this.address.zipcode + ' ' + this.address.city;
            }
            return '';
        },
        anyLinks() {
            if (this.infos !== null) {
                for (const [key, value] of Object.entries(this.infos.linkTo)) {
                    //console.log(`${key}: ${value}`);
                    if (value !== null) {
                        return true;
                    }
                }
            }
            return false;
        },
        hasOnlyUrl() {
            var urlGiven = false;
            var smGiven = false;
            if (this.infos !== null) {
                for (const [key, value] of Object.entries(this.infos.linkTo)) {
                    if (key == 'url' && value !== null) {
                        urlGiven = true;
                    }
                    else if (value !== null) {
                        smGiven = true;
                    }
                }

                if (urlGiven == true && smGiven == false) {
                    return true;
                }
            }
            return false;
        },
        costs() {
            if (this.infos !== null && Object.prototype.hasOwnProperty.call(this.infos, 'costs') && this.infos.costs !== null) {
                var cost = this.infos.costs.split(';');
                if (cost.length > 1) {
                    return cost;
                }
                else if (cost.length == 1) {
                    return cost[0]
                }
            }
            return null;
        }
    },
    methods: {
        shortenUrl,
        toggleSocialMediaLinks() {
            this.showSocialMediaLinks = this.showSocialMediaLinks == false ? true : false;
        },
        toggleMap() {
            this.showMap = this.showMap == false ? true : false;
        },
        togglePrices() {
            this.showPrices = this.showPrices == false ? true : false;
        },
        getSocialMediaIcons(smType) {
            var basePath = "/assets/icons/social/d/";
            if (this.target == "mobile") {
                basePath = "/assets/icons/social/";
            }
            return basePath + smType + '.png';
        }
    },
}
</script>

<style lang="scss">
@import '@/scss/_variables.scss';

.info-block {

    .info {
        justify-content: normal !important;
        display: flex;

        li {
            flex: 1;

            @media (min-width: 1400px) {
                margin-right: 40px !important;

                &:last-child {
                    margin-right: 0 !important;
                }
            }
        }
    }

    .desktop {
        a {
            color: #fff;

            &:hover {
                color: #cecece;
            }
        }

        .info-list__more {
            display: none;
        }

        .address-block button {
            color: #fff !important;
            cursor: default !important;
            text-decoration: none !important;
        }
    }

    .mobile {
        .address-block button {
            text-align: left;
        }

        a {
            line-height: 1;
            color: #3A3A3A;

            &:hover {
                color: #cecece;
            }
        }

        button.btn.btn-link {
            line-height: 1.7;
            font-size: 12px;
            color: rgba(0, 0, 0, 0.6);
            text-transform: none;
            text-decoration: underline;
            font-weight: normal;

            &:hover {
                text-decoration: normal !important;
            }
        }

        .info-list {
            max-width: 100% !important;
            position: relative;
            display: flex;
            align-items: flex-start;
            margin-bottom: 15px;
            cursor: pointer;
        }

        .info-list.info-list--center {
            align-items: center;
        }
    }

    .card {
        display: flex;
        flex-direction: row;
        border: none;
        border-radius: 3px;
        box-shadow: 0px 2px 4px 0px #00000040;
        padding: 15px;
        min-height: 142px;
        align-items: center;
        margin-bottom: 15px;

        &-image {
            margin-left: 5px;
            margin-right: 25px;
        }

        &-content {
            align-self: flex-start;

            &__title {
                text-transform: uppercase;
                color: $black-color;
                margin-bottom: 10px;
            }

            &__list {
                li {
                    font-size: 13px;
                    line-height: 18px;
                    margin-bottom: 12px;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }

        &-rtl {
            .card-image {
                margin-left: 25px;
                margin-right: 5px;
            }
        }
    }
}

.social-item {
    a {
        @media (min-width: 992px) {
            position: absolute;
        }
    }
}
</style>