<template>

  <div class="termin">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12 col-sm-6">
          <custom-date-picker :initialValue="dateTimeSelection.selectedStartDate" label="Start-Datum wählen"
            @changedDate="startDateChanged" />
        </div>
        <div class="col-12 col-sm-6">
          <custom-date-picker :initialValue="dateTimeSelection.selectedEndDate" label="End-Datum wählen (optional)"
            @changedDate="endDateChanged" />
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-sm-6">
          <time-picker :initialValue="dateTimeSelection.selectedStartTime" label="Start-Uhrzeit ab"
            @changedTime="startTimeChanged" />
        </div>
        <div class="col-12 col-sm-6">
          <time-picker :initialValue="dateTimeSelection.selectedEndTime" label="End-Uhrzeit bis (optional)"
            @changedTime="endTimeChanged" />
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import { compareAndSwitchDates } from '@/utils/appointment-helpers';

export default {
  name: 'TimeFilters',
  components: {
    TimePicker: () => import('@/components/controls/TimePicker'),
    CustomDatePicker: () => import('@/components/controls/CustomDatePicker'),
  },
  props: {
    initialValueSet: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      dateTimeSelection: {
        selectedEndDate: null,
        selectedStartDate: null,
        selectedStartTime: null,
        selectedEndTime: null,
      }
    }
  },
  created() {
    /*set the time to the initialValue*/
    if (this.initialValueSet !== null) {
      this.dateTimeSelection = this.initialValueSet;
    }
  },
  computed: {
    readableStartDate() {
      if (this.dateTimeSelection.selectedStartDate != null) {
        let d = this.dateTimeSelection.selectedStartDate === null ? new Date() : new Date(this.dateTimeSelection.selectedStartDate);
        let day = getWeekday(d.getDay()).substring(0, 3);
        let month = getMonth(d.getMonth() + 1).substring(0, 3);
        return day + ', ' + d.getDate() + '.' + ' ' + month + ' ' + d.getFullYear();
      }
      return '';
    },
    readableStartTime() {
      if (this.dateTimeSelection.selectedStartTime != null) {
        let d = this.dateTimeSelection.selectedStartTime === null ? new Date() : new Date(this.dateTimeSelection.selectedStartTime);
        return d.getHours() + ' : ' + d.getMinutes();
      }
      return ''
    },
  },
  methods: {
    startDateChanged(dateValue) {
      this.dateTimeSelection.selectedStartDate = dateValue;
      var resObject = compareAndSwitchDates(this.dateTimeSelection.selectedStartDate, this.dateTimeSelection.selectedEndDate);
      this.dateTimeSelection.selectedStartDate = resObject.start;
      this.dateTimeSelection.selectedEndDate = resObject.end;
      this.$emit('changedTimeFilters', this.dateTimeSelection);
    },
    endDateChanged(dateValue) {
      this.dateTimeSelection.selectedEndDate = dateValue;
      var resObject = compareAndSwitchDates(this.dateTimeSelection.selectedStartDate, this.dateTimeSelection.selectedEndDate);
      this.dateTimeSelection.selectedStartDate = resObject.start;
      this.dateTimeSelection.selectedEndDate = resObject.end;
      this.$emit('changedTimeFilters', this.dateTimeSelection);
    },
    startTimeChanged(timeValue) {
      this.dateTimeSelection.selectedStartTime = timeValue;
      var resObject = compareAndSwitchDates(this.dateTimeSelection.selectedStartTime, this.dateTimeSelection.selectedEndTime);
      this.dateTimeSelection.selectedStartTime = resObject.start;
      this.dateTimeSelection.selectedEndTime = resObject.end;
      this.$emit('changedTimeFilters', this.dateTimeSelection);
    },
    endTimeChanged(timeValue) {
      this.dateTimeSelection.selectedEndTime = timeValue;
      var resObject = compareAndSwitchDates(this.dateTimeSelection.selectedStartTime, this.dateTimeSelection.selectedEndTime);
      this.dateTimeSelection.selectedStartTime = resObject.start;
      this.dateTimeSelection.selectedEndTime = resObject.end;
      this.$emit('changedTimeFilters', this.dateTimeSelection);
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/scss/_variables.scss';
@import '@/scss/_mixins.scss';

.termin {
  position: absolute;
  width: 100vw;
  top: $title-bar-size;
  height: auto;
  padding: 15px 0;
  background: $white-color;
  z-index: inherit;
  box-shadow: 0px 4px 4px 0px #00000040;
}
</style>